import router from './router';
const witeList = [''];
router.beforeEach(async (to:any, from:any, next:Function) => {
  if(to.query.token){
    localStorage.setItem('token',to.query.token)
  }
  if(to.query.customer_id){
    localStorage.setItem('customer_id',to.query.customer_id)
  }
  next();
});
