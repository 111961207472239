import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { defineAsyncComponent } from 'vue'
const _import = (path:String) => defineAsyncComponent(() => import(`../views/${path}.vue`));
const routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "home"
	},
  {
		path: "/PartnerUserDecoration",
		name: "PartnerUserDecoration",
    component:() => import(/* webpackChunkName: 'login' */'../views/PartnerUserDecoration/index.vue')
	},
];
export default createRouter({
    routes: routes,
    history: createWebHistory()
})
