const directiveScroll = (app) => {
  let directiveTimer=[]
  app.directive('autoScroll', {
    mounted(el, binding, vnode) {
           //设置定时器,每50毫秒列表向下滑动1单位
       let timer=setInterval(() => {
          //判断是否到底,如果到底,恢复到顶
          if (
            el.scrollTop >=
            el.scrollHeight - el.clientHeight
          ) {
            el.scrollTop = 0;
          } else {
            el.scrollTop += 1;
          }
        }, 30);
        directiveTimer.push(timer)
    },
    unmounted(el, binding, vnode) {
      // 当指令与元素解除绑定且父组件已销毁时调用。
      // directiveTimer.map(v=>{
      //   clearInterval(v)
      // })
    }
  })
}

export default directiveScroll