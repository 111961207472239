import { createApp } from 'vue'
import './style.css'
import VueRouter from './router/index'
import './styles/less/atom.less'
import './styles/less/common.less'
import pinia from './store/index'
import App from './App.vue'
import registerDirectives from '@/directives/index'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import './styles/base.less';
import "./permission"
const app=createApp(App)
registerDirectives(app)
app.use(VueRouter)
app.use(pinia)
app.use(Antd)
app.mount('#app')
