<script setup>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  // import 'dayjs/locale/zh-cn';
  import {ref} from 'vue'
  // dayjs.locale('zh-cn');
 const  locale=ref(zhCN)
</script>

<template>
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
</template>
